import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Blog.js';
import App from './App';
import Button from '@material-ui/core/Button';
import { createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme.js';



ReactDOM.render(<React.StrictMode>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </React.StrictMode>,
        document.getElementById('root'));