import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import background from "./img/andrepieter.jpg";
import logo from "./img/translogo.png"
import facebooklogo from "./img/facebookLogo.png"

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#fff"
    },
    hero: {
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${background})`,
        height: "500px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontSize: "4rem",
    },
    blogsContainer: {
        paddingTop: theme.spacing(3),
    },
    blogTitle: {
        fontWeight: 800,
        paddingBottom: theme.spacing(3),
    },
    navitem: {
        padding:theme.spacing(30),
    },
    navlink: {
        padding:theme.spacing(3),
    },
    navlogo: {
        width:"5rem",
        height:"5rem",
    },
    navbar: {
        alignItems:"center",
        justifyContent:"space-between",
        padding: "0.5rem,2.5rem",
        position: "absolute",
        zIndex: "1",
        width: "100%",

    }

}));



function App() {
    const classes = useStyles();

    return (

        <div className="App">
            <AppBar className={classes.appBar} position="static">
                <Box className="navbarBox">
                <Toolbar className="navbar">
                    <div>
                        <img src={logo} className="navbarlogo" alt="logo" />
                    </div>
                    <a href="https://logbook.pestscan.eu/ps_web/Login.aspx?VESP">
                        <Box className="navitem navitem1">
                            <Typography variant="h6" color="primary" >
                                <p className="navlink">Uw logboek</p>
                            </Typography>
                        </Box>
                    </a>
                    <a className="plaagLink" href="https://www.boerenwinkel.nl/nl/dealer/519647/categorie/3382-ONGEDIERTE">
                        <Box className="navitem navitem2">
                            <Typography variant="h6" color="primary" >
                                <p className="navlink">Webshop voor particulieren ></p>
                            </Typography>
                        </Box>
                    </a>
                </Toolbar>
                </Box>
            </AppBar>
            <Box className="bodyBox">
            {/*<Box className={classes.hero}>*/}
            {/*    <Box>*/}
            {/*        <p className="pictureTitle">Vespula</p>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            <Container maxWidth="lg" className={classes.blogsContainer}>
                <Typography variant="h4" className={classes.blogTitle}>
                    Over ons
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className="aboutBoxes">
                            <h4>Wie en wat is vespula</h4>
                            <p>
                                Vespula is een profesioneel ongediertebestrijdings bedrijf dat is gevestigd te Erica, wat bestaat uit 2 ongediertebestrijders genaamd Pieter van der Meijden en Andre Harder.
                                Verder is Vespula ook volledig gediplomeerd ( IKB-PSB , VCA-VOL)
                            </p>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className="aboutBoxes">
                            <h4>Veel pluspunten</h4>
                            <ul>
                                <li><strong> De snelste en de voordeligste</strong></li>
                                <li> Wij staan altijd voor u klaar</li>
                                <li> Zelfs in het weekend (in overleg)</li>
                                <li> Tijdens vakanties geopend</li>
                                <li> In uw omgeving is Vespula reeds een betrouwbare naam</li>
                                <li>Vespula is schappelijk </li>
                            </ul>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card className="aboutBoxes">
                            <h4>Contact</h4>
                            <p className="contactText"><strong>Vespula </strong>is gevestigd te Erica<br/>
                                Pieter van der Meijden<br/>
                                Andre Harder<br/>
                                Telefoon: 06 489 20 335<br/>
                                Kamer van koophandel: 0407 62 02 <br/>
                                BTW nummer: 1392 47 336 B01
                            </p>
                            <a href="https://www.facebook.com/pages/category/Pest-Control-Service/Vespula-420301725387417/"><img
                                className="facebookLink" src={facebooklogo} alt="Facebook logo" /></a>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>

                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>

                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.blogsContainer}>
                <Typography variant="h4" className={classes.blogTitle}>
                    Wat wij bestrijden
                </Typography>
                <p>Veel voorkomende overlast en plagen veroorzakers zoals:</p>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                        <Card className="plaagTableCard">
                            <table className="plaagTable" cellSpacing="3" cellPadding="5" bordercolor="red" border="solid">
                                <tbody>
                                <tr>
                                    <td align="center">wespen</td>
                                    <td align="center">muizen</td>
                                    <td align="center">ratten</td>
                                    <td align="center">mollen</td>
                                    <td align="center">zilvervisjes</td>
                                    <td align="center">papiervisjes</td>
                                    <td align="center">marters</td>


                                </tr>
                                <tr>
                                    <td align="center">kakkerlakken</td>
                                    <td align="center">vliegen</td>
                                    <td align="center">mieren</td>
                                    <td align="center">spinnen</td>
                                    <td align="center">houtworm</td>
                                    <td align="center">boktor</td>
                                    <td align="center">zwammen</td>
                                </tr>
                                <tr>
                                    <td align="center">duiven</td>
                                    <td align="center">schimmels</td>
                                    <td align="center">bijen</td>
                                    <td align="center">vlooien</td>
                                    <td align="center">maden</td>
                                    <td align="center">insecten</td>
                                    <td align="center">knaagdieren</td>
                                </tr>

                                </tbody>
                            </table>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg" className={classes.blogsContainer}>
                <p> Maar ook onderstaande beestjes kunnen vervelende problemen opleveren: </p>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={12}>
                        <Card className="plaagTableCard">
                            <table className="plaagTable" cellSpacing="3" cellPadding="5" bordercolor="red" border="solid">
                                <tbody>
                                <tr>


                                    <td align="center">stofmijt</td>
                                    <td align="center">vogelmijt</td>
                                    <td align="center">luizen</td>
                                    <td align="center">bedwants</td>


                                </tr>
                                <tr>
                                    <td align="center">muggen</td>
                                    <td align="center">kevers</td>
                                    <td align="center">graankevers</td>
                                    <td align="center">meeltorren</td>


                                </tr>
                                <tr>
                                    <td align="center">broodkevers</td>
                                    <td align="center">tabakskevers</td>
                                    <td align="center">voorraadaantastende<br/>
                                        motten en mijten</td>
                                    <td align="center">hommels</td>

                                </tr>
                                <tr>
                                    <td align="center">ovenvisjes</td>
                                    <td align="center">kamervliegen</td>
                                    <td align="center">vleesvliegen</td>
                                    <td align="center">gaasvliegen</td>


                                </tr>
                                <tr>
                                    <td align="center">loopkevers</td>
                                    <td align="center">lapsnuitkevers</td>
                                    <td align="center">pissebedden</td>
                                    <td align="center">oorwormen</td>

                                </tr>
                                <tr>

                                    <td align="center">hommels</td>
                                    <td align="center">ovenvisjes</td>
                                    <td align="center">kamervliegen</td>
                                    <td align="center">vleesvliegen</td>
                                </tr>

                                <tr>


                                    <td align="center"> stofluizen</td>
                                    <td align="center">spinthoutkevers</td>
                                    <td align="center">heipaalkevers</td>
                                    <td align="center">blauwschimmels</td>
                                </tr>

                                <tr>

                                    <td align="center">spinthoutkevers</td>
                                    <td align="center">heipaalkevers</td>
                                    <td align="center">kelderzwammen</td>

                                </tr>
                                <tr>
                                    <td align="center">blauwschimmels</td>
                                    <td align="center">huiszwammen</td>
                                    <td align="center">tapijtkevers</td>
                                </tr>
                                </tbody>
                            </table>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            </Box>
            <footer className={classes.footer}>
                <Container maxWidth="lg">
                    <Typography variant="h6" align="center" gutterBottom>

                    </Typography>
                    <Typography variant="subtitle1" align="center" color="textSecondary" component="p">

                    </Typography>

                </Container>
            </footer>
        </div>

    );
}

export default App;
